export const menuItems = [
  {
    path: "/",
    text: "Laja Team",
  },
  {
    path: "/products",
    text: "produkty",
  },
  {
    path: "/blogs",
    text: "blog",
  },
  {
    path: "/contact",
    text: "kontakt",
  },
]
