export const menuItems = [
  {
    path: "/",
    text: "Laja Team",
  },
  {
    path: "/about",
    text: "O nas",
  },
  {
    path: "/news",
    text: "Baza wiedzy",
  },
  {
    path: "/contact",
    text: "kontakt",
  },
]
